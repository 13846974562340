import React from "react"
import {
  Box,
  Button,
  Flex,
  Image,
  Spacer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Link,
  IconButton,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from "gatsby"
import { TriangleDownIcon, HamburgerIcon } from "@chakra-ui/icons"

import YaraCoffeeClubLogo from "../assets/images/yara-coffeeclub-logo.svg"
import { sectionHyperlinks, headerHeight } from "../utils/constant"

const Header = () => {
  return (
    <Box
      as="header"
      position="sticky"
      top="0"
      left="0"
      right="0"
      width="full"
      shadow="md"
      backgroundColor="#fff"
      zIndex="2"
    >
      <Flex
        position="relative"
        alignItems="center"
        width="full"
        maxWidth="7xl"
        height={headerHeight}
        mx="auto"
        px="6"
      >
        <LogoLink />
        <Spacer />
        <HStack spacing="10">
          <SpreadedOptions />
          <HStack spacing="3">
            <LanguageMenu />
            <OptionsHamburgerMenu />
          </HStack>
        </HStack>
      </Flex>
    </Box>
  )
}

export default Header

const LogoLink = () => (
  <Link
    as={GatsbyLink}
    to="/"
    borderRadius="md"
    _hover={{ textDecoration: "none" }}
  >
    <HStack>
      <Image src={YaraCoffeeClubLogo} height="2.425rem" loading="eager" />
    </HStack>
  </Link>
)

const SpreadedOptions = () =>
  sectionHyperlinks.map(({ label, href }, index) => (
    <Link
      as={GatsbyLink}
      display={{ base: "none", lg: "initial" }}
      key={`header-link-${index + 1}`}
      to={href}
      color="inherit"
      fontWeight="600"
      borderRadius="md"
    >
      {label}
    </Link>
  ))

const OptionsHamburgerMenu = () => (
  <Box display={{ base: "initial", lg: "none" }}>
    <Menu isLazy lazyBehavior matchWidth preventOverflow strategy="fixed">
      <MenuButton
        as={IconButton}
        size="sm"
        aria-label="options"
        icon={<HamburgerIcon />}
        background="inherit"
        transition="all 0.2s"
        borderRadius="md"
        borderWidth="0.1rem"
        fontSize={{ base: "sm", sm: "inherit" }}
      />
      <MenuList>
        {sectionHyperlinks.map(({ label, href }, index) => (
          <MenuItem key={`header-hamburger-menu-link-${index + 1}`}>
            <Box as={GatsbyLink} to={href} width="full">
              {label}
            </Box>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  </Box>
)

const LanguageMenu = () => (
  <Menu isLazy lazyBehavior matchWidth preventOverflow strategy="fixed">
    <MenuButton
      as={Button}
      size="sm"
      background="inherit"
      rightIcon={<TriangleDownIcon width="2" />}
      px="3"
      transition="all 0.2s"
      borderRadius="md"
      borderWidth="0.1rem"
      fontSize={{ base: "sm", sm: "inherit" }}
    >
      Eng
    </MenuButton>
    <MenuList>
      <MenuItem>English</MenuItem>
      <MenuItem>বাংলা</MenuItem>
      <MenuItem>हिन्दी</MenuItem>
      <MenuItem>ಕನ್ನಡ</MenuItem>
      <MenuItem>मराठी</MenuItem>
      <MenuItem>ਪੰਜਾਬੀ</MenuItem>
      <MenuItem>தமிழ்</MenuItem>
      <MenuItem>తెలుగు</MenuItem>
      <MenuItem>Thailand</MenuItem>
    </MenuList>
  </Menu>
)
