import React from "react"
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  Link,
} from "@chakra-ui/react"

import YaraLogo from "../assets/images/yara-logo.svg"

const Footer = () => {
  return (
    <Box width="full" backgroundColor="ahuaBlue.90" color="white">
      <Box maxWidth="7xl" mx="auto" px="6" py={{ base: 10, md: 16 }}>
        <Grid
          alignItems="center"
          width="full"
          templateColumns={{ base: "unset", md: "repeat(2, 1fr)" }}
          rowGap="6"
        >
          <GridItem>
            <HStack spacing="6">
              <Image
                alignSelf={{ base: "flex-start", md: "center" }}
                src={YaraLogo}
                boxSize={{ base: "50px", md: "60px" }}
                alt="yara logo"
              />
              <Text fontSize="sm">
                Copyright &copy; {new Date().getFullYear()} Yara. All Rights
                Reserved. This website uses cookies to give you the best, most
                relevant experience. By using this website you give us consent
                to store your cookie as per our Privacy Policy.
              </Text>
            </HStack>
          </GridItem>
          <GridItem
            rowStart={{ base: 1, md: "unset" }}
            justifySelf={{ base: "self-start", md: "self-end" }}
            width="full"
          >
            <HStack
              spacing="6"
              justifyContent={{ base: "space-between", md: "flex-end" }}
              width="full"
            >
              <Link
                href="https://www.yara.com/privacy-and-legal/digital-farming-privacy/"
                isExternal
              >
                Privacy &amp; legal
              </Link>
              <Link
                href="https://www.yara.com/privacy-and-legal/digital-farming-terms/"
                isExternal
              >
                Terms of use
              </Link>
            </HStack>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer
