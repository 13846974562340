import React from "react"

import Layout from "../components/Layout"
import Banner from "../components/HomePage/Banner"
import KeyFeatures from "../components/HomePage/KeyFeatures"
import HowItWorks from "../components/HomePage/HowItWorks"
import Teams from "../components/HomePage/Teams"
import CallToAction from "../components/HomePage/CallToAction"

const HomePage = () => {
  return (
    <Layout>
      <Banner />
      <KeyFeatures />
      <HowItWorks />
      <Teams />
      <CallToAction />
    </Layout>
  )
}

export default HomePage
